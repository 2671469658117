import React from 'react'
import { AnswerParagraph, AnswersContainer, ButtonsQR, ContentContainer, QRCodeDiv, QrCodeWithButtonsContainer, RowContainer, SubitemParagraph, TitleQR } from './ModalDetailPrint/StylesQRCode';
import QRCode from 'react-qr-code';
import { Accordion, AccordionDetails, AccordionSummary, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CreateUpdateBody, ModeloQRCode } from '../../service/QrCode';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { useToPng } from '@hugocxl/react-to-image';
import OpenPrintBase64 from '../../helpers/OpenPrintBase64';

function RenderOptions({ renderBody, modeloQrCode } : { renderBody: CreateUpdateBody, modeloQrCode: ModeloQRCode }) {
  const findNameInModel: (indexItem: number, indexSubitem: number) => string = (indexItem: number, indexSubitem: number) => {
    let result = "-";
    Object.keys(modeloQrCode).forEach((key: string) => {
      const splittedKey = key.split("||");
      if (Number(splittedKey[0]) === indexItem && Number(splittedKey[1]) === indexSubitem) {
        result = modeloQrCode[key].nome;
      }
    })
    return result;
  }

  return (
    <AnswersContainer>
      {Object.values(renderBody.respostasModelo).map(val => (
        <>
          <RowContainer>
            <SubitemParagraph>
              {findNameInModel(val.itemId, val.subitemId)}:
            </SubitemParagraph>
            <AnswerParagraph>
              {val.ocorrencia}
            </AnswerParagraph>
            {val.ocorrencia === renderBody.id && (
              <Tooltip title={"Subitem Identificador"}>
                <InfoIcon style={{ marginLeft: '5px', color: '#643E91', alignSelf: 'center' }}/>
              </Tooltip>
            )}
          </RowContainer>
        </>
      ))}
    </AnswersContainer>
  )
}


type params = {
  qrCodeData: string;
  renderBody: CreateUpdateBody;
  modeloQrCode: ModeloQRCode;
  hideSpan?: boolean;
}

export default function RenderQrCode({ qrCodeData, renderBody, modeloQrCode, hideSpan } : params) {
  let isPrinting = false;

  const [state, convertToPng, ref] = useToPng<HTMLDivElement>({
    onSuccess: data => {
      if (isPrinting) {
        OpenPrintBase64(data);
        isPrinting = false;
      } else {
        let a = document.createElement("a");
        a.href = data
        a.download = `QrCode-${renderBody.id}.png`;
        a.click();
      }
    }
  })

  return (
    <QrCodeWithButtonsContainer>
      {!hideSpan && (
        <span
          className='tag is-warning'
          style={{ marginBottom: '10px' }}
        >
          Você ja possui um QrCode de pré preenchimento nesse identificador, gerar outro irá subistituir esse
        </span>
      )}
      <QRCodeDiv ref={ref}>
        <QRCode
          size={256}
          style={{ width: "80%" }}
          value={qrCodeData}
        />
        <TitleQR>{renderBody.nome}</TitleQR>
      </QRCodeDiv>
      <ContentContainer>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Exibir Respostas que virão pré-preenchidas nesse QR-Code
          </AccordionSummary>
          <AccordionDetails>
            <RenderOptions renderBody={renderBody} modeloQrCode={modeloQrCode} />
          </AccordionDetails>
        </Accordion>
      </ContentContainer>
      <ButtonsQR>
        <button 
          className='button is-success is-small'
          onClick={() => {
            isPrinting = true;
            convertToPng();
          }}
          disabled={state.isLoading}
        >
          Imprimir&nbsp;
          <LocalPrintshopIcon />
        </button>
        <button
          className='button is-info is-small'
          onClick={() => convertToPng()}
          disabled={state.isLoading}
        >
          Baixar&nbsp;
          <DownloadIcon />
        </button>
      </ButtonsQR>
    </QrCodeWithButtonsContainer>
  )
}
