import { Card, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DivItemsCabecMaior, DivItemsCabeMenor, DivLogo, GenericHeader, H1NomeItem, H3NomeSubitem, H3Preview, ModalCard, ModalCardbody } from '../../../StyledComponents/Modal/generic';
import { FormExampleContainer } from '../FormStyle';
import { TitleText } from '../../Teams/style/RegisteredTeamsStyle';
import { PrintTemplateType } from '../Types';
import checkBrightness from '../../../helpers/checkBrightness';
import { trackEventMatomo } from '../../../helpers/matomo';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { MdCancel } from 'react-icons/md';

export default function PrintPreviewCreateModal({
  printTemplate,
  image,
  printPreviewModal,
  setPrintPreviewModal,
  setCanShow,
}
  : {
    printTemplate: PrintTemplateType,
    image: string,
    printPreviewModal: Boolean,
    setPrintPreviewModal: Function,
    setCanShow: Function
  }) {
  const { t } = useTranslation('translation');

  const componentRef = useRef(null);

  const itensEsquerda = [`Cliente:`, `Local:`, `Conjunto:`, `Unidade:`, `identificador:`, `Scoring:`]
  const itensDireita = [`Equipes:`, `Usuário:`, `Inicio:`, `Fim:`, `Aprovação:`, 'Range']

  return (
    <Card>
      <TitleText>
        <div className={`modal ${printPreviewModal ? "modal is-active" : "modal"}`}>
          <div className="modal-background" onClick={() => {
            trackEventMatomo('PrintPreviewCreateModal', 'click', 'modal', 'fecha modal')
            setPrintPreviewModal(false);
            setCanShow(true);
          }}
          />
          <ModalCard className="modal-card" onSubmit={(e: React.FormEvent) => e.preventDefault()} style={{ width: '60%' }}>
            <header className="modal-card-head">
              <p className="modal-card-title">Preview de impressão</p>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  trackEventMatomo('PrintPreviewCreateModal', 'click', 'button', 'fecha modal')
                  setPrintPreviewModal(false)
                  setCanShow(true);
                }} />
            </header>
            <ModalCardbody className="modal-card-body">
              <div style={{ fontSize: '14px' }}>
                Legenda:
                <p>
                  <div className="icon-text">
                    <span className="icon has-text-success">
                      <IoMdCheckmarkCircle className='is-success' />
                    </span>
                    <span>Avaliação em conformidade</span>
                  </div>
                </p>
                <p>
                  <div className="icon-text">
                    <span className="icon has-text-success">
                      <MdCancel className='icon has-text-danger is-small' />
                    </span>
                    <span>Avaliação em não conformidade</span>
                  </div>
                </p>
              </div>
              {!printTemplate.logo ? <></> : (
                <img alt="Logo da empresa" src={image} width={150} style={{alignSelf: 'center', marginBottom: '10px'}}/>
              )}
              <DivLogo style={{
                backgroundColor: printTemplate.color,
              }}>
                <div style={{ backgroundColor: printTemplate.color, padding: '10px' }}>
                  <p
                    style={{
                      fontSize: `${printTemplate.headerText?.size}px`,
                      fontWeight: printTemplate.headerText?.bold ? 'bold' : 500,
                      fontStyle: printTemplate.headerText?.italic ? 'italic' : 'normal',
                      color: !printTemplate.color?.length ? 'black' : (checkBrightness(printTemplate?.color) ? 'black' : 'white'),
                      fontFamily: 'Sans Serif'
                    }}
                  >
                    {printTemplate.headerText?.text}
                  </p>
                </div>
              </DivLogo>
              <FormExampleContainer ref={componentRef}>
                <>
                  <h3>
                    {'Nome do Formulário'}
                  </h3>
                  <H3Preview>
                    {'Nome da empresa'}
                  </H3Preview>
                  <DivItemsCabecMaior className='columns'>
                    <DivItemsCabeMenor className='column'>
                      {itensEsquerda.map((elem) => (
                        <p>{elem}</p>
                      ))}
                    </DivItemsCabeMenor>
                    <DivItemsCabeMenor className='column'>
                      {itensDireita.map((elem) => (
                        <p>{elem}</p>
                      ))}
                    </DivItemsCabeMenor>
                  </DivItemsCabecMaior>
                </>
                <div>
                  <>
                    <Typography variant="h6" style={{ textDecoration: 'underline', color: 'gray' }}>
                      Nome do item
                    </Typography>
                    <span style={{ fontWeight: '100' }}>
                      &nbsp;- Scoring:
                    </span>
                    <Typography variant='subtitle2' style={{ textDecoration: 'underline', color: 'gray' }}>
                      subitens:
                    </Typography>

                  </>
                  <ul>
                    <Typography style={{ color: 'gray', fontWeight: 'bold' }} variant='subtitle2'>
                      <div style={{ display: 'flex' }}>
                        <span style={{ fontWeight: 500 }}>
                          (1)
                        </span>&nbsp;
                        - Nome do subitem
                      </div>
                      <p style={{ color: 'gray', fontWeight: '300' }}>(tipo do subitem)</p>
                    </Typography>
                  </ul>
                </div>
              </FormExampleContainer>
              <div style={{ backgroundColor: printTemplate.color, padding: '10px' }}>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: `${printTemplate.footerText?.size}px`,
                    fontWeight: printTemplate.footerText?.bold ? 'bold' : 500,
                    fontStyle: printTemplate.footerText?.italic ? 'italic' : 'normal',
                    color: (!printTemplate.color ? 'black' : printTemplate.color && checkBrightness(printTemplate.color) ? 'black' : 'white')
                  }}
                >
                  {printTemplate.footerText?.text}
                </p>
              </div>
            </ModalCardbody>
          </ModalCard>
        </div>
      </TitleText>
    </Card>
  )
}
