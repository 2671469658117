import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GenericHeader } from '../../../StyledComponents/Modal/generic';
import AttToken from '../../../helpers/attToken';
import QrCode, { CreateUpdateBody, ModeloQRCode, RespostasModeloType } from '../../../service/QrCode';
import { ChecklistReportType } from '../../../service/Reports';
import { toast } from 'react-toastify';
import { Skeleton } from '@mui/material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { MainContainerInfo } from './StylesQRCode';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RenderQrCode from '../RenderQrCode';

type params = {
  identificador: { nome: string, resposta: string },
  id: string,
  openModal: boolean,
  setOpenModal: Function,
  selected: ChecklistReportType
}

export default function ModalQRCode({ id, identificador, openModal, setOpenModal, selected } : params) {
  const [isLoadingQrCode, setIsLoadingQrCode] = useState(true);
  const [qrCodeData, setQrCodeData] = useState<string>();

  const qrCodeService = useMemo(() => new QrCode(), []);

  if (!selected.modeloQrCode) {
    toast.warn('QrCode Não Configurado!')
    setOpenModal(false);
  }

  const fetchQrCode = useCallback(async () => {
    setIsLoadingQrCode(true);
    const token = await AttToken();
    if (token) {
      const qrCode = await qrCodeService.getById({...token, formId: id, identificador: identificador.resposta});
      console.log("AAAA", qrCode)
      if (qrCode && qrCode.deepLink) {
        setQrCodeData(qrCode.deepLink)
      }
    }
    setIsLoadingQrCode(false);
  }, [id, identificador, qrCodeService]);

  const findRespostasModelo: (checklist: ChecklistReportType) => RespostasModeloType | null = (checklist: ChecklistReportType) => {
    if (!checklist.modeloQrCode) {
      return null
    }
    const respostasModelo: RespostasModeloType = {}

    Object.keys(checklist.modeloQrCode).forEach((key: string) => {
      const splitedKey = key.split("||");
      const itemIndex = Number(splitedKey[0]);
      const subitemIndex = Number(splitedKey[1]);
      if (!isNaN(itemIndex) && !isNaN(subitemIndex)) {
        const subItem = checklist.itens[itemIndex].subitens[subitemIndex];
        if (subItem && subItem.checked) {
          respostasModelo[key] = {
            subitemId: subitemIndex,
            itemId: itemIndex,
            tipo: subItem.tipo,
          }
          if (subItem.img) respostasModelo[key].foto = subItem.img;
          if (subItem.tipo === 'avaliacao') {
            respostasModelo[key].nota = subItem.nota
            respostasModelo[key].comentario = subItem.ocorrencia || null
          } else if (['textoCurto', 'textoLongo', 'data', 'hora', 'numero', 'email', 'telefone', 'rg', 'cpfcnpj', 'cep', 'placa'].includes(subItem.tipo)) {
            respostasModelo[key].ocorrencia = subItem.ocorrencia
            respostasModelo[key].comentario = subItem.obs || null
          } else if (['unique', 'list'].includes(subItem.tipo)) {
            respostasModelo[key].selecionados = subItem.selecionados
            respostasModelo[key].comentario = subItem.obs || null
          } else if (subItem.tipo === 'multiple') {
            respostasModelo[key].selecionados = subItem.selecionados
            respostasModelo[key].comentario = subItem.ocorrencia || null
          } else if (subItem.tipo === 'assinatura') {
            respostasModelo[key].ocorrencia = subItem.assinatura
            respostasModelo[key].comentario = subItem.obs || null
          }
        }
      }
    })
    
    if (respostasModelo) {
      return respostasModelo
    }
    return null
  }

  const getQrCodeObject: (checklist: ChecklistReportType) => CreateUpdateBody | null = (checklist: ChecklistReportType) => {
    const respostasModelo = findRespostasModelo(checklist);
    if (respostasModelo) {
      const objToSend: CreateUpdateBody = {
        nome: `${identificador.nome}: ${identificador.resposta}`,
        id: identificador.resposta,
        modeloId: id,
        respostasModelo
      }
      return objToSend;
    }
    return null;
  }


  const createQrCode = async (updateBody: CreateUpdateBody) => {
    await toast.promise(async () => {
      setIsLoadingQrCode(true);
      const token = await AttToken();
      if (token) {
        const qrCodeData = await qrCodeService.createUpdateQrCode({...token, ...updateBody});
        console.log(qrCodeData);
        setQrCodeData(qrCodeData.deepLink)
      }
      setIsLoadingQrCode(false);
    }, { pending: 'Criando QrCode', success: 'Qr Code Criado', error: 'Falha ao criar QrCode!' })
  }

  useEffect(() => {
    if (isLoadingQrCode) {
      fetchQrCode();
    }
  }, [fetchQrCode, isLoadingQrCode]);

  return (
      <div className={`modal ${openModal ? "modal is-active" : "modal"}`}>
        <div className="modal-background" onClick={() => setOpenModal && setOpenModal(false)} />
        <div className="modal-card" style={{ minWidth: '750px', width: '50%' }}>
          <header className="modal-card-head" style={{ display: "flex", maxWidth: '100%', justifyContent: 'space-between', flexDirection: 'column' }}>
            <div>
              <GenericHeader>
                QR Code
              </GenericHeader>
            </div>
          </header>
          <div className="modal-card-body" style={{ overflowX: 'hidden', padding: '2em' }}>
            {isLoadingQrCode && (
              <div style={{ width: '100%', height: '500px' }}>
                <Skeleton height={'100%'}/>
              </div>
            )}
            {!isLoadingQrCode && !getQrCodeObject(selected) && (
              <h1>Desculpe, mas esse Formulário não pode gerar QR Codes de pré preenchimento</h1>
            )}
            {!isLoadingQrCode && (
              <MainContainerInfo style={{ maxHeight: qrCodeData ? '1000px' : '100px' }}>
                {!qrCodeData && <span className='tag is-warning'>Você ainda não possui um QrCode de pré preenchimento nesse identificador</span>}
                {qrCodeData && getQrCodeObject(selected) && (
                  <RenderQrCode
                    qrCodeData={qrCodeData}
                    renderBody={getQrCodeObject(selected) as CreateUpdateBody}
                    modeloQrCode={selected.modeloQrCode as ModeloQRCode}
                  />
                )}
                <button
                  className='button is-big is-black'
                  onClick={async () => {
                    await createQrCode(getQrCodeObject(selected) as CreateUpdateBody)
                  }}
                >
                  {`Gerar${qrCodeData ? ' Outro' : ''} QR Code`}&nbsp;&nbsp;
                  <QrCodeIcon />
                </button>
              </MainContainerInfo>
            )}
          </div>
          <footer className="modal-card-foot">
            <div className="buttons">
              <button
                className="button is-fullwidth is-info"
                onClick={() => setOpenModal(false)}
                disabled={isLoadingQrCode}
              >
                <ArrowBackIcon />&nbsp;
                Voltar
              </button>
            </div>
          </footer>
        </div>
      </div>
    )
}
