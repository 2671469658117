import { TextField } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import { trackEventMatomo } from '../../helpers/matomo';
import { PTextOfSelect } from '../Reports/Style';

export default function AutocompleteSemApi({
  noOptionText,
  value,
  arrayData,
  setState,
  label,
}: {
  noOptionText: string,
  value: { label: string, value: string }[] | undefined,
  arrayData: { id: string, nome: string }[] | [],
  setState: Function
  label: string,
}) {

  function checkValuesLenght(value: { value: string, label: string }[]) {
    if (value.length >= 30) {
      return true;
    } else return false
  }

  return (
    <>
      {checkValuesLenght(value as { label: string, value: string }[])
        && <PTextOfSelect>Este filtro atingiu valor máximo de 30 opções, remova ou substitua opções.</PTextOfSelect>}
      <Autocomplete
        getOptionDisabled={() => checkValuesLenght(value as { label: string, value: string }[])}
        autoComplete
        onClick={() => trackEventMatomo('Relatórios', 'click', 'select', 'seleciona valor')}
        className='column'
        noOptionsText={noOptionText}
        multiple
        value={value}
        getOptionLabel={option => option.label as string}
        getOptionKey={option => option.value}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        id="combo-box-demo"
        filterSelectedOptions
        options={arrayData
          ?.sort((a: { id: string, nome: string }, b: { id: string, nome: string }) => { return a.nome?.toLowerCase() > b.nome?.toLowerCase() ? 1 : - 1 })
          ?.map((option: { id: string, nome: string }) => ({
            label: option.nome,
            value: option.id
          }))}
        onChange={(event: any, newValue: { label: string, value: string }[] | null) => {
          setState(newValue)
        }}
        sx={{
          tag: {
            "& MuiChip-label MuiChip-labelMedium css-6od3lo-MuiChip-label": {
              color: "red !important",
              backgroundColor: 'red !important'
            }
          }
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.value}>
            <span>{option.label}</span>
          </li>)}
        ChipProps={{
          sx: {
            maxWidth: '250px !important',
          }
        }}
        renderInput={(params) =>
          <TextField
            {...params}
            variant="outlined"
            label={label} />}
      />
    </>
  )
}